import React from 'react';
import useGlobalHook from 'use-global-hook';

import * as actions from '../actions';
import * as services from '../services';

const mergedActions = {
    ...actions,
    ...services,
};

const initialState = {
    status: 'INITIAL',
    blogContent: '',
    blogPosts: '',
    content: '',
    contentFilter: '',
    contentTypeMappings: {},
    selectedBlog: '',
    totalPages: '',
    truckerBurger: '',
};

const useGlobal = useGlobalHook(React, initialState, mergedActions);

export default useGlobal;
