import React, { Component } from 'react';
import styled from 'styled-components';
// import { Event } from '../../Tracking/Tracking';
import Button from '../Button';
import Logo from '../UI/Logo';

const MenuStyled = styled.div`
    width: 81%;

    @media screen and (min-width: 1024px) {
        width: calc(100% - 140px);
        display: flex;
        justify-content: space-between;
        align-items: center;

        nav {
            display: flex;
            justify-content: ${props =>
                !props.hideNav ? 'space-between' : 'flex-end'};
            align-items: center;

            & > button {
                margin-right: 32px;
            }

            button,
            a {
                color: #fff;
                background: none;
                border: none;
                font: 0.875rem ${props => props.theme.fonts[1]}, sans-serif;
                text-decoration: none;
                text-transform: uppercase;
                cursor: pointer;

                &:hover {
                    color: ${props => props.theme.colors.primaryYellow};
                }

                &.active {
                    color: ${props => props.theme.colors.primaryYellow};

                    &:hover {
                        color: #fff;
                    }
                }
            }

            .call-now,
            .fleet-insider {
                margin-right: ${props => (props.hideNav ? '43px' : '32px')};
            }

            .button {
                a {
                    color: ${props => props.theme.colors.primaryGray};
                }

                &:hover {
                    a {
                        color: ${props => props.theme.colors.primaryYellow};
                    }
                }
            }
        }
    }

    @media screen and (min-width: 1330px) {
        width: calc(100% - 150px);
        nav {
            & > button,
            & > .call-now,
            .fleet-insider {
                margin-right: 60px;
            }
        }
    }
`;

class Menu extends Component {
    navItemClicked = (buttonName, buttonId) => {
        var activeItems = document.getElementsByClassName('active');
        for (let i = 0; i < activeItems.length; i++) {
            activeItems.item(i).classList.remove('active');
        }
        document.getElementById(buttonId).classList.add('active');

        window.dataLayer.push({
            event: `${buttonName} Button Clicked`,
            pagePath: '',
            pageTitle: '',
        });
    };
    render() {
        const navItems = [
            { id: 'what-is-one9-nav', text: 'What is One9' },
            { id: 'where-to-fuel-nav', text: 'Where to Fuel' },
        ];

        const nav = navItems.map(item => {
            return (
                <button
                    id={item.id}
                    key={item.id}
                    className={
                        this.props.activeLink === item.id ? 'active' : null
                    }
                    onClick={e => {
                        this.navItemClicked(item.text, item.id);
                        this.props.menuItemClicked(e);
                    }}
                >
                    {item.text}
                </button>
            );
        });

        return (
            <MenuStyled hideNav={this.props.hideNav} className="menu">
                <Logo
                    {...this.props.logoProps}
                    clicked={this.props.logoClicked}
                />
                <nav>
                    {!this.props.hideNav ? (
                        <React.Fragment>
                            {nav}
                            <a className="fleet-insider" href="/blog">
                                Fleet Insider
                            </a>
                            <a className="call-now" href="tel:1-865-292-6639">
                                Contact Us
                            </a>
                            <Button
                                color="yellow"
                                id="how-to-apply-nav"
                                url="/benefits"
                                text="Apply"
                                buttonSidePadding="50px"
                            />
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <a className="fleet-insider" href="/blog">
                                Fleet Insider
                            </a>
                            <a className="call-now" href="tel:1-865-292-6639">
                                Contact Us
                            </a>
                            <Button
                                color="yellow"
                                id="how-to-apply-nav"
                                url="/benefits"
                                text="Apply"
                                buttonSidePadding="50px"
                            />
                        </React.Fragment>
                    )}
                </nav>
            </MenuStyled>
        );
    }
}

export default Menu;
