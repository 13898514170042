import axios from 'axios';
import { applyDateFormat, applyContentLabels } from './BlogUtilitiesService.js';
const blogUrl = process.env.PFJ_BLOG_URL;

// Get the four most recent blogs
export const getRelatedBlogs = async store => {
    // Locate the related blogs in the global store if they already exist there
    var locatedBlogs;
    if (
        store.state.blogPosts !== null &&
        store.state.blogPosts.length > 3 &&
        store.state.selectedBlog !== null &&
        store.state.selectedBlog !== ''
    ) {
        for (var x = 0; x < store.state.blogPosts.length; x++) {
            if (
                store.state.blogPosts[x].slug === store.state.selectedBlog.slug
            ) {
                if (x < store.state.blogPosts.length - 3) {
                    locatedBlogs = [
                        store.state.blogPosts[x + 1],
                        store.state.blogPosts[x + 2],
                        store.state.blogPosts[x + 3],
                    ];
                }
            }
        }
    }

    if (
        locatedBlogs == null &&
        (store.state == null ||
            store.state.selectedBlog == null ||
            store.state.selectedBlog === '')
    ) {
        return null;
    }

    var relatedBlogs;

    if (locatedBlogs != null) {
        relatedBlogs = locatedBlogs;
    } else {
        var filterQueryText;
        // Exclude the current blog slug if available
        if (
            store.state.selectedBlog == null ||
            store.state.selectedBlog === ''
        ) {
            filterQueryText =
                '{"filter": {"path": "data.publicationDate.iv", "op": "le", "value":"' +
                store.state.selectedBlog.originalPublicationDate +
                '"}, "sort":[{"path": "data.publicationDate.iv", "order":"descending"}], "take":3 }';
        } else {
            filterQueryText =
                '{"filter": {"and": [{"path": "data.publicationDate.iv", "op": "le", "value":"' +
                store.state.selectedBlog.originalPublicationDate +
                '"}, {"path": "data.slug.iv", "op": "ne", "value":"' +
                store.state.selectedBlog.slug +
                '"}]}, "sort":[{"path": "data.publicationDate.iv", "order":"descending"}], "take":3 }';
        }

        var encodedFilterQuery = encodeURIComponent(filterQueryText);

        const response = await axios.get(blogUrl + encodedFilterQuery);
        relatedBlogs = response?.data?.items;

        // If the current post is one of the 3-oldest in the system, retrieve the 3 most recent posts instead.
        if (response?.data?.items.length < 3) {
            if (
                store.state.selectedBlog == null ||
                store.state.selectedBlog === ''
            ) {
                filterQueryText =
                    '{"sort":[{"path": "data.publicationDate.iv", "order":"descending"}], "take":3 }';
            } else {
                filterQueryText =
                    '{"filter": {"path": "data.slug.iv", "op": "ne", "value":"' +
                    store.state.selectedBlog.slug +
                    '"}, "sort":[{"path": "data.publicationDate.iv", "order":"descending"}], "take":3 }';
            }

            encodedFilterQuery = encodeURIComponent(filterQueryText);

            const mostRecentResponse = await axios.get(
                blogUrl + encodedFilterQuery
            );
            relatedBlogs = mostRecentResponse?.data?.items;
        }

        relatedBlogs = await applyDateFormat(relatedBlogs);
        relatedBlogs = await applyContentLabels(relatedBlogs);
    }
    if (
        store.state.selectedBlog.slug != null &&
        store.state.selectedBlog.slug !== ''
    ) {
        var relatedPostsOriginalSlug = store.state.selectedBlog.slug;
        store.setState({ relatedPostsOriginalSlug });
    }
    store.setState({ relatedBlogs });
    return relatedBlogs;
};
