import axios from 'axios';
import { applyDateFormat, applyContentLabels } from './BlogUtilitiesService.js';

const blogUrl = process.env.PFJ_BLOG_URL;

export const getPosts = async (store, request = axios, contentFilter) => {
    const status = 'LOADING';

    request = axios;

    store.setState({ status });

    try {
        var innerFilter,
            contentFilters,
            dateFilters = '';

        // Create the content type filter for use in the query

        if (
            contentFilter.activeTypeFilters != null &&
            contentFilter.activeTypeFilters.length > 0
        ) {
            contentFilters =
                '"path": "data.contentType.iv", "op": "in", "value":';

            contentFilters += '[';

            // Construct content filter portion of query

            for (var i = 0; i < contentFilter.activeTypeFilters.length; i++) {
                contentFilters +=
                    '"' + contentFilter.activeTypeFilters[i] + '"';

                contentFilters +=
                    i !== contentFilter.activeTypeFilters.length - 1 ? ',' : '';
            }

            contentFilters += ']';
        }

        // Create the date filter for use in the query

        if (contentFilter.activeDaysFilter !== 0) {
            var dateFilterOperation =
                contentFilter.activeDaysFilter < 0 ? 'le' : 'ge';

            var currentDate = new Date();

            currentDate.setDate(
                currentDate.getDate() - Math.abs(contentFilter.activeDaysFilter)
            );

            currentDate = currentDate.toISOString();

            dateFilters =
                '"path": "data.publicationDate.iv", "op": "' +
                dateFilterOperation +
                '", "value":"' +
                currentDate +
                '"';
        }

        // Create the "exclude featured blogs" portion of the filter
        var featuredFilter =
            '"path": "data.isFeatured.iv", "op": "eq", "value":false';

        // Scenario 1: Both date and content type filters are set

        if (
            contentFilters != null &&
            contentFilters !== '' &&
            dateFilters != null &&
            dateFilters !== ''
        ) {
            innerFilter =
                '"and": [{' +
                contentFilters +
                '}, {' +
                dateFilters +
                '}, {' +
                featuredFilter +
                '}]';
        }

        // Scenario 2: Only content type filters are set
        else if (
            contentFilters !== '' &&
            contentFilters != null &&
            dateFilters === ''
        ) {
            innerFilter =
                '"and": [{' + contentFilters + '}, {' + featuredFilter + '}]';
        }

        // Scenario 3: Only date filters are set
        else if (
            (contentFilters === '' || contentFilters == null) &&
            dateFilters !== '' &&
            dateFilters != null
        ) {
            innerFilter =
                '"and": [{' + dateFilters + '}, {' + featuredFilter + '}]';
        } else {
            innerFilter = featuredFilter;
        }

        var filterQuery = '';

        if (innerFilter != null && innerFilter !== '') {
            filterQuery = '{"filter": {' + innerFilter + '}, ';
        } else {
            filterQuery = '{';
        }

        var sortQuery =
            '"sort":[{"path": "data.publicationDate.iv", "order":"descending"}]';

        var skipQuery =
            contentFilter.activePage !== 1
                ? ', "skip":' +
                  contentFilter.pageSize * (contentFilter.activePage - 1) +
                  ''
                : '';

        var takeQuery = ', "take":' + contentFilter.pageSize + ' }';

        var filterQueryText = filterQuery + sortQuery + skipQuery + takeQuery;

        var encodedFilterQuery = encodeURIComponent(filterQueryText);

        const response = await request.get(blogUrl + encodedFilterQuery);

        const status = 'SUCCESS';

        var getPostsResponse = response.data.items;

        // Apply content labels to the response

        getPostsResponse = await applyContentLabels(getPostsResponse);

        // Apply date formatting to the response

        getPostsResponse = await applyDateFormat(getPostsResponse);

        // Do not update the total pages for "get most recent 4 posts" calls.

        if (contentFilter.pageSize !== 4) {
            var totalPages = Math.ceil(
                response.data.total / contentFilter.pageSize
            );

            var totalPosts = response.data.total;

            totalPages = totalPages === 0 ? totalPages + 1 : totalPages;

            store.setState({
                totalPosts,
                totalPages,
                status,
            });
        } else {
            store.setState({ status });
        }

        return getPostsResponse;
    } catch (error) {
        console.log(error);

        const isError404 = error.response && error.response.status === 404;

        const status = isError404 ? 'NOT_FOUND' : 'ERROR';

        store.setState({ status });
    }
};
