import { getPosts } from './BlogCoreService.js';
import { getFeaturedBlog } from './FeaturedArticleService.js';

export const getFilteredBlogsService = async (store, contentFilter) => {
    var splitContentFilters = [];
    var convertedContentFilter = contentFilter;
    if (contentFilter != null) {
        for (var i = 0; i < contentFilter.activeTypeFilters?.length; i++) {
            // If "all" is selected then clear all content type filters
            if (contentFilter.activeTypeFilters[i] === '*') {
                convertedContentFilter.activeTypeFilters = null;
                break;
            }
            // If any of the filters have a comma, split the string and make each substring an element in activeTypeFilters
            if (contentFilter.activeTypeFilters[i].indexOf(',') > -1) {
                var separatedFilters = contentFilter.activeTypeFilters[i].split(
                    ','
                );
                convertedContentFilter.activeTypeFilters[i] =
                    separatedFilters[0];
                for (var x = 1; x < separatedFilters.length; x++) {
                    splitContentFilters.push(separatedFilters[x]);
                }
            }
        }

        // Add the remaining comma delimited filters back into activeTypeFilters
        for (var z = 0; z < splitContentFilters.length; z++) {
            convertedContentFilter.activeTypeFilters.push(
                splitContentFilters[z]
            );
        }
    }

    // Reset page size to 9 when not using "show more" on the mobile blog listing page
    if (!contentFilter.mobileShowMoreActive) {
        contentFilter.pageSize = 9;
    }

    var currentFiltersAlreadyPresent = false;
    // Prevent API call if currently-requested filters match the already present filters
    if (
        store.state.contentFilter != null &&
        store.state.contentFilter.activePage === contentFilter.activePage &&
        store.state.contentFilter.pageSize === contentFilter.pageSize &&
        store.state.contentFilter.activeTypeFilters?.length ===
            contentFilter.activeTypeFilters?.length &&
        store.state.contentFilter.activeDaysFilter ===
            contentFilter.activeDaysFilter &&
        contentFilter.forceApiCall !== true
    ) {
        contentFilter.forceApiCall = false;
        for (var j = 0; j < store.state.activeTypeFilters?.length; j++) {
            if (
                !contentFilter.activeTypeFilters.contains(
                    store.state.contentFilter.activeTypeFilters[j]
                )
            ) {
                break;
            }
        }
        currentFiltersAlreadyPresent = true;
    }

    if (currentFiltersAlreadyPresent) {
        return store.state.blogPosts;
    } else {
        var blogPosts = await getPosts(
            store,
            undefined,
            convertedContentFilter
        );
        if (
            convertedContentFilter.activePage === 1 &&
            (convertedContentFilter.activeTypeFilters == null ||
                convertedContentFilter.activeTypeFilters?.length === 0) &&
            convertedContentFilter.activeDaysFilter === '0'
        ) {
            var featuredPost = await getFeaturedBlog(store);

            if (featuredPost != null || featuredPost !== undefined) {
                blogPosts.unshift(featuredPost);
            }
        }
        store.setState({ contentFilter, blogPosts });

        return blogPosts;
    }
};
