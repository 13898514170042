import * as blogCoreService from './BlogCoreService';
import * as BlogDetailsServices from './BlogDetailsServices';
import * as FilteredArticlesServices from './FilteredArticlesServices';
import * as RecentArticlesService from './RecentArticlesService';
import * as RelatedArticlesService from './RelatedArticlesService';
import * as FeaturedArticleService from './FeaturedArticleService';
import * as LoadNextBlogList from './LoadNextBlogList';
import * as BlogPreviousNextService from './BlogPreviousNextService';
import * as BlogUtilitiesService from './BlogUtilitiesService';

const services = {
    ...blogCoreService,
    ...BlogDetailsServices,
    ...FilteredArticlesServices,
    ...RecentArticlesService,
    ...RelatedArticlesService,
    ...FeaturedArticleService,
    ...LoadNextBlogList,
    ...BlogPreviousNextService,
    ...BlogUtilitiesService,
};

export { services };
