import axios from 'axios';
import jsonData from '../data/filteredData.json';

export const getBlogsContent = async (store, request = axios) => {
    let status = 'LOADING';
    store.setState({ status });
    try {
        const blogContent = [...jsonData];

        const isContentEmpty = blogContent.length === 0;
        status = isContentEmpty ? 'EMPTY' : 'SUCCESS';
        store.setState({ status, blogContent });
    } catch (error) {
        const isError404 = error.response && error.response.status === 404;
        status = isError404 ? 'NOT_FOUND' : 'ERROR';
        store.setState({ status });
    }
};
