export const applyContentLabels = async blogPosts => {
    var labelMappings = {
        'article-small-image': 'article',

        'article-large-image': 'article',

        video: 'video',

        'case-study': 'case study',

        infographic: 'infographic',
    };

    for (var i = 0; i < blogPosts.length; i++) {
        if (blogPosts[i] != null && blogPosts[i] !== undefined) {
            blogPosts[i].contentTypeLabel =
                labelMappings[blogPosts[i]?.contentType];
        }
    }

    return blogPosts;
};

export const applyDateFormat = async blogPosts => {
    for (var i = 0; i < blogPosts.length; i++) {
        var publicationDate = new Date(blogPosts[i].publicationDate);

        blogPosts[i].originalPublicationDate = blogPosts[i].publicationDate;

        blogPosts[i].publicationDate =
            publicationDate.toDateString().substring(4, 7) +
            ' ' +
            publicationDate.getDate() +
            ', ' +
            publicationDate.getFullYear();
    }
    return blogPosts;
};

export const getIndexOfBlog = (blog, blogList) => {
    if (blog != null && blogList != null) {
        for (var i = 0; i < blogList.length; i++) {
            if (blog.slug === blogList[i].slug) {
                return i;
            }
        }
    }
    return -1;
};
