import React from 'react';

const hamburger = props => (
    <svg
        className="hamburger"
        width="38px"
        height="38px"
        viewBox="0 0 38 38"
        version="1.1"
        onClick={props.clicked}
    >
        <g
            id="Symbols-"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g
                id="logo_menu_mobile"
                transform="translate(-297.000000, -6.000000)"
            >
                <g id="logo_menu">
                    <g
                        id="hamburger_menu"
                        transform="translate(297.000000, 6.000000)"
                    >
                        <rect
                            id="Rectangle"
                            fill="#F9A11B"
                            x="0"
                            y="0"
                            width="38"
                            height="38"
                            rx="2"
                        ></rect>
                        <g
                            id="Hamburger-Menu"
                            strokeWidth="1"
                            transform="translate(7.000000, 11.000000)"
                            fill="#212721"
                        >
                            <rect
                                id="Rectangle-Copy"
                                x="0"
                                y="0"
                                width="24"
                                height="3"
                            ></rect>
                            <rect
                                id="Rectangle-Copy-2"
                                x="0"
                                y="7"
                                width="24"
                                height="3"
                            ></rect>
                            <rect
                                id="Rectangle-Copy-3"
                                x="0"
                                y="14"
                                width="24"
                                height="3"
                            ></rect>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default hamburger;
