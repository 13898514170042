import axios from 'axios';
import { applyDateFormat, applyContentLabels } from './BlogUtilitiesService.js';
const blogUrl = process.env.PFJ_BLOG_URL;

// Get the four most recent blogs
export const getFeaturedBlog = async store => {
    try {
        var filterQueryText =
            '{"filter": {"path": "data.isFeatured.iv", "op": "eq", "value":true}}, "sort":[{"path": "data.publicationDate.iv", "order":"descending"}], "take":1 }';

        var encodedFilterQuery = encodeURIComponent(filterQueryText);

        const response = await axios.get(blogUrl + encodedFilterQuery);

        var featuredBlog = response?.data?.items;

        featuredBlog = await applyDateFormat(featuredBlog);
        featuredBlog = await applyContentLabels(featuredBlog);
        featuredBlog = featuredBlog[0];

        store.setState({ featuredBlog });
        return featuredBlog;
    } catch (error) {
        const isError404 = error.response && error.response.status === 404;
        console.log(isError404);
    }
};
