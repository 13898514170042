import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import scrollToElement from 'scroll-to-element';
import styled from 'styled-components';
import GlobalStyles from './global-styles';

import Theme from '../components/Theme';

import Header from './Header';
import Footer from './Footer';
import useGlobal from '../store';
const LayoutStyled = styled.div`
    width: 100%;
`;
const Layout = ({ ...props }) => {
    const { children, hideNav, needsSolidHeader, size } = props;
    const [showMobileNav, setShowMobileNav] = useState(false);
    const [globalState, globalActions] = useGlobal();

    useEffect(() => {
        if (globalState.status !== 'SUCCESS') {
            var contentFilter = {
                activePage: 1,
                pageSize: 20,
                activeDaysFilter: 0,
                activeTypeFilters: null,
            };
            globalActions.services.getFilteredBlogsService(contentFilter);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (globalState.status !== 'SUCCESS') {
            globalActions.pageContent.getPageContent();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const scrollToElementHandler = event => {
        event.preventDefault();
        let element = '';
        const elementArray = event.target.id.split('-nav');
        element = document.getElementById(elementArray[0]);

        scrollToElement(element, {
            offset: -142,
            duration: 1000,
        });
    };

    return (
        <Theme>
            <LayoutStyled>
                <Helmet>
                    <link
                        rel="stylesheet"
                        href="https://use.typekit.net/tms1nuc.css"
                    />
                </Helmet>
                <Header
                    hideNav={hideNav}
                    isDesktop={size.width >= 1024}
                    needsSolidHeader={needsSolidHeader}
                    showMobileNav={showMobileNav}
                    closeClicked={() => {
                        setShowMobileNav(false);
                    }}
                    hamburgerClicked={() => {
                        setShowMobileNav(true);
                    }}
                    menuItemClicked={scrollToElementHandler}
                />
                <div className="container">
                    <main>{children}</main>
                </div>
                <Footer />
            </LayoutStyled>
            <GlobalStyles />
        </Theme>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    hideNav: PropTypes.bool,
    needsSolidHeader: PropTypes.bool,
};

Layout.defaultProps = {
    hideNav: false,
    needsSolidHeader: false,
};

export default Layout;
