import React, { Component } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
// import { Event } from '../../Tracking/Tracking';

import Logo from '../UI/Logo';
import NavClose from '../UI/NavClose';

const MobileNavStyled = styled.nav`
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: ${props => props.theme.colors.primaryYellow};
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    box-sizing: border-box;
    z-index: 99999;

    .nav__wrapper {
        display: flex;
        flex-direction: column;

        a,
        button {
            margin: 21px;
            color: ${props => props.theme.colors.primaryGray};
            font: 1.375rem ${props => props.theme.fonts[1]}, sans-serif;
            line-height: 1.9375rem;
            text-transform: uppercase;
            background-color: transparent;
            border: none;
            cursor: pointer;
            text-align: left;

            &.active,
            &:hover {
                color: #fff;
            }
        }

        &:last-child {
            margin-bottom: 61px;
        }

        .mobile-nav__item__divider {
            width: 290px;
            height: 1px;
            background-color: rgba(255, 255, 255, 0.5);
        }
    }

    .mobile-nav__top-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 92px;
        padding: 0 20px;
        box-sizing: border-box;

        .menu__close {
            cursor: pointer;
        }
    }

    &.open {
        top: 0;
        margin-top: 0;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        transition: margin-top 0.5s ease-in-out;
    }
`;

class MobileNav extends Component {
    state = {};

    navItemClicked = (buttonName, buttonId) => {
        var activeItems = document.getElementsByClassName('active');
        for (let i = 0; i < activeItems.length; i++) {
            activeItems.item(i).classList.remove('active');
        }
        document.getElementById(buttonId).classList.add('active');

        window.dataLayer.push({
            event: `${buttonName} Button Clicked`,
            pagePath: '',
            pageTitle: '',
        });
        this.props.closeClicked();
    };

    render() {
        const { closeClicked, hideNav, logoClicked } = this.props;

        const navItems = [
            { id: 'what-is-one9-nav', text: 'What is One9' },
            { id: 'where-to-fuel-nav', text: 'Where to Fuel' },
        ];

        const nav = navItems.map((item, key) => {
            return (
                <React.Fragment key={key}>
                    <button
                        id={item.id}
                        className="nav-item"
                        onClick={e => {
                            this.navItemClicked(item.text, item.id);
                            this.props.menuItemClicked(e);
                        }}
                    >
                        {item.text}
                    </button>
                    <span className="mobile-nav__item__divider"></span>
                </React.Fragment>
            );
        });

        const logoProps = {
            className: 'header__logo',
            oneColor: '#FEFEFE',
            nineColor: '#212721',
            squareColor: '#FEFEFE',
            fuelNetworkColor: '#FEFEFE',
            width: '119',
            height: '50',
        };
        return (
            <MobileNavStyled className="mobile-nav">
                <div className="mobile-nav__top-row">
                    <Logo {...logoProps} clicked={logoClicked} />
                    <NavClose clicked={closeClicked} />
                </div>
                <div className="nav__wrapper">
                    {!hideNav ? (
                        <React.Fragment>
                            {nav}
                            <Link to="/blog">Fleet Insider</Link>
                            <span className="mobile-nav__item__divider"></span>
                            <a
                                className="call-now"
                                href="tel:1-865-292-6639"
                                onClick={closeClicked}
                            >
                                Contact Us
                            </a>
                            <span className="mobile-nav__item__divider"></span>
                            <Link to="/benefits">Apply</Link>
                            <span className="mobile-nav__item__divider"></span>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <Link to="/blog" onClick={closeClicked}>
                                Fleet Insider
                            </Link>
                            <span className="mobile-nav__item__divider"></span>
                            <a
                                className="call-now"
                                href="tel:1-865-292-6639"
                                onClick={closeClicked}
                            >
                                Contact Us
                            </a>
                            <span className="mobile-nav__item__divider"></span>
                            <Link to="/benefits" onClick={closeClicked}>
                                Apply
                            </Link>
                            <span className="mobile-nav__item__divider"></span>
                        </React.Fragment>
                    )}
                </div>
            </MobileNavStyled>
        );
    }
}

export default MobileNav;
