import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

const logo = props => (
    <Link to="/">
        <svg
            className={props.className}
            width={props.width}
            height={props.height}
            viewBox="0 0 759 338"
            version="1.1"
        >
            <title>One9 Fuel Network Logo</title>
            <g
                id="Page-1"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g id="one9_black" fillRule="nonzero">
                    <rect
                        id="square"
                        fill={props.squareColor}
                        fill="#FAA01A"
                        x="513.5"
                        y="0"
                        width="245.25"
                        height="245.25"
                        rx="34.34"
                    ></rect>
                    <path
                        d="M701.43,80.64 L701.43,164.92 C701.43,191 687.61,204.38 660.85,204.38 L610.91,204.38 C584.16,204.38 570.56,191 570.56,165.14 C570.476936,164.021856 570.55099,162.897588 570.78,161.8 L610.69,156.89 L610.69,158.45 C610.69,167.14 613.81,170.27 622.95,170.27 L648.81,170.27 C658.18,170.27 661.3,167.14 661.3,158.45 L661.3,141.51 C657.51,144.63 652.6,146.19 645.47,146.19 L610.91,146.19 C584.16,146.19 570.34,132.81 570.34,106.73 L570.34,80.64 C570.34,54.56 584.16,41.18 610.91,41.18 L660.85,41.18 C687.61,41.18 701.43,54.56 701.43,80.64 Z M661.3,100.26 L661.3,87.11 C661.3,78.19 658.18,75.29 648.81,75.29 L623,75.29 C613.64,75.29 610.52,78.19 610.52,87.11 L610.52,100.26 C610.52,108.96 613.64,111.85 623,111.85 L648.86,111.85 C658.18,111.85 661.3,109 661.3,100.26 Z"
                        id="nine"
                        fill={props.nineColor}
                    ></path>
                    <path
                        d="M134.12,79.79 L134.12,166.38 C134.12,193.12 120.19,206.83 92.77,206.83 L41.36,206.83 C13.94,206.83 0,193.12 0,166.38 L0,79.79 C0,53.05 13.94,39.34 41.36,39.34 L92.77,39.34 C120.19,39.34 134.12,53.05 134.12,79.79 Z M80.43,74.3 L53.69,74.3 C44.33,74.3 41.13,77.3 41.13,86.41 L41.13,159.76 C41.13,168.67 44.33,171.87 53.69,171.87 L80.43,171.87 C89.8,171.87 93,168.67 93,159.76 L93,86.41 C93,77.27 89.8,74.3 80.43,74.3 Z"
                        className="one__letter"
                        fill={props.oneColor}
                    ></path>
                    <polygon
                        className="one__letter"
                        fill={props.oneColor}
                        points="314.85 41.63 314.85 204.54 274.85 204.54 216.58 109.72 216.58 204.54 175.47 204.54 175.47 41.63 215.22 41.63 273.72 136.45 273.72 41.63"
                    ></polygon>
                    <polygon
                        className="one__letter"
                        fill={props.oneColor}
                        points="397.49 76.59 397.49 103.78 462.38 103.78 462.38 138.78 397.49 138.78 397.49 169.62 472 169.62 472 204.62 356.36 204.62 356.36 41.63 472 41.63 472 76.63"
                    ></polygon>
                    <polygon
                        className="fuel-network__letter"
                        fill={props.fuelNetworkColor}
                        points="52.9 296.82 52.9 307.91 73.9 307.91 73.9 317.91 52.9 317.91 52.9 337.19 41.24 337.19 41.24 286.89 76.85 286.89 76.85 296.89"
                    ></polygon>
                    <path
                        d="M134.06,325.29 C134.06,333.69 129.75,337.86 121.06,337.86 L106.72,337.86 C98.1,337.86 93.79,333.69 93.79,325.29 L93.79,286.85 L105.45,286.85 L105.45,323.45 C105.45,326.77 106.65,327.9 110.18,327.9 L117.67,327.9 C121.21,327.9 122.41,326.77 122.41,323.45 L122.41,286.85 L134.06,286.85 L134.06,325.29 Z"
                        className="fuel-network__letter"
                        fill={props.fuelNetworkColor}
                    ></path>
                    <polygon
                        className="fuel-network__letter"
                        fill={props.fuelNetworkColor}
                        points="165.42 296.82 165.42 306.42 186.42 306.42 186.42 316.42 165.42 316.42 165.42 327.22 189.42 327.22 189.42 337.22 153.76 337.22 153.76 286.92 189.37 286.92 189.37 296.92"
                    ></polygon>
                    <polygon
                        className="fuel-network__letter"
                        fill={props.fuelNetworkColor}
                        points="243.52 327.19 243.52 337.19 208.76 337.19 208.76 286.89 220.42 286.89 220.42 327.23"
                    ></polygon>
                    <polygon
                        className="fuel-network__letter"
                        fill={props.fuelNetworkColor}
                        points="337.46 286.85 337.46 337.15 326.09 337.15 306.8 306.07 306.8 337.15 295.15 337.15 295.15 286.85 306.45 286.85 325.81 317.94 325.81 286.85"
                    ></polygon>
                    <polygon
                        className="fuel-network__letter"
                        fill={props.fuelNetworkColor}
                        points="368.46 296.82 368.46 306.42 389.46 306.42 389.46 316.42 368.46 316.42 368.46 327.22 392.46 327.22 392.46 337.22 356.8 337.22 356.8 286.92 392.41 286.92 392.41 296.92"
                    ></polygon>
                    <polygon
                        className="fuel-network__letter"
                        fill={props.fuelNetworkColor}
                        points="450.53 296.82 435.76 296.82 435.76 337.15 424.1 337.15 424.1 296.82 409.34 296.82 409.34 286.82 450.53 286.82"
                    ></polygon>
                    <polygon
                        className="fuel-network__letter"
                        fill={props.fuelNetworkColor}
                        points="537.74 286.85 525.52 337.15 509.41 337.15 501.07 298.79 492.74 337.15 476.63 337.15 464.34 286.85 476.14 286.85 484.68 326 493.16 286.86 508.91 286.86 517.46 326 526 286.85"
                    ></polygon>
                    <path
                        d="M593.75,298.72 L593.75,325.29 C593.75,333.69 589.44,337.86 580.75,337.86 L565.56,337.86 C556.94,337.86 552.63,333.69 552.63,325.29 L552.63,298.72 C552.63,290.32 556.94,286.15 565.56,286.15 L580.75,286.15 C589.44,286.15 593.75,290.32 593.75,298.72 Z M577.36,296.11 L569,296.11 C565.47,296.11 564.26,297.24 564.26,300.56 L564.26,323.45 C564.26,326.77 565.47,327.9 569,327.9 L577.33,327.9 C580.86,327.9 582.06,326.77 582.06,323.45 L582.06,300.56 C582.09,297.24 580.89,296.11 577.36,296.11 Z"
                        className="fuel-network__letter"
                        fill={props.fuelNetworkColor}
                    ></path>
                    <path
                        d="M631.31,318.93 L624.74,318.93 L624.74,337.15 L613.08,337.15 L613.08,286.85 L639.86,286.85 C648.48,286.85 652.79,291.02 652.79,299.43 L652.79,306.43 C652.79,313 650.1,316.96 644.73,318.37 L658,337.15 L644.31,337.15 L631.31,318.93 Z M624.74,308.93 L636.4,308.93 C639.93,308.93 641.13,307.8 641.13,304.48 L641.13,301.23 C641.13,297.91 639.93,296.78 636.4,296.78 L624.74,296.78 L624.74,308.93 Z"
                        className="fuel-network__letter"
                        fill={props.fuelNetworkColor}
                    ></path>
                    <polygon
                        className="fuel-network__letter"
                        fill={props.fuelNetworkColor}
                        points="689.16 316.67 684.21 316.67 684.21 337.15 672.55 337.15 672.55 286.85 684.21 286.85 684.21 306.71 689.08 306.71 702.58 286.85 716.14 286.85 699.4 311.65 717.2 337.15 703.57 337.15"
                    ></polygon>
                    <path
                        d="M724.58,327.55 L733.52,327.55 L733.52,329.28 L730.05,329.28 L730.05,337.2 L728.05,337.2 L728.05,329.28 L724.58,329.28 L724.58,327.55 Z M746.25,327.55 L746.25,337.2 L744.25,337.2 L744.25,330.33 L741.08,335.08 L740.48,335.08 L737.32,330.3 L737.32,337.2 L735.32,337.2 L735.32,327.55 L737.57,327.55 L740.79,332.42 L744.02,327.55 L746.25,327.55 Z"
                        className="fuel-network__letter"
                        fill={props.fuelNetworkColor}
                    ></path>
                </g>
            </g>
        </svg>
    </Link>
);

logo.propTypes = {
    className: PropTypes.string.isRequired,
    oneColor: PropTypes.string.isRequired,
    nineColor: PropTypes.string.isRequired,
    squareColor: PropTypes.string.isRequired,
    fuelNetworkColor: PropTypes.string.isRequired,
    width: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired,
};

export default logo;
