import React, { Component } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import Logo from './UI/Logo';

const FooterStyled = styled.footer`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 45px 0;
    background-color: ${props => props.theme.colors.primaryGray};
    font-size: 0.7rem;
    box-sizing: border-box;

    @media screen and (min-width: 1160px) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 45px 197px;
    }

    div {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 240px;
        margin-bottom: 28px;

        @media screen and (min-width: 1160px) {
            flex-direction: row;
            width: initial;
            margin-bottom: 0;
        }

        &:nth-of-type(2) {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;

            a {
                margin-bottom: 8px;

                @media screen and (min-width: 1160px) {
                    margin-bottom: 0;

                    &:nth-of-type(2) {
                        &:after {
                            content: ' ';
                            display: inline-block;
                            border: 1px solid #fff;
                            border-radius: 50%;
                            height: 1px;
                            width: 1px;
                            margin: 0 8px 2px;
                            background-color: #fff;
                        }
                    }
                }

                &:nth-of-type(1) {
                    &:after {
                        content: ' ';
                        display: inline-block;
                        border: 1px solid #fff;
                        border-radius: 50%;
                        height: 1px;
                        width: 1px;
                        margin: 0 8px 2px;
                        background-color: #fff;
                    }
                }
            }
        }
    }

    .footer__logo {
        margin-bottom: 26px;

        @media screen and (min-width: 1160px) {
            margin: 0 24px 0 0;
        }
    }

    .footer__copy {
        text-align: center;
    }

    .copyright {
        color: #fff;
        line-height: 1rem;
        text-align: center;

        @media screen and (min-width: 1160px) {
            &:nth-of-type(1) {
                margin-right: 4px;
            }
        }
    }
`;

class Footer extends Component {
    footerItemClicked = buttonName => {
        window.dataLayer.push({
            event: `${buttonName} Button Clicked`,
            pagePath: '',
            pageTitle: '',
        });
    };

    render() {
        return (
            <FooterStyled>
                <div className="logo-and-copyright">
                    <Logo
                        className="footer__logo"
                        oneColor="#FEFEFE"
                        nineColor="#FEFEFE"
                        squareColor="#F9A11B"
                        fuelNetworkColor="#FEFEFE"
                        width="100"
                        height="42"
                    />
                    <span className="copyright">
                        &copy; {new Date().getFullYear()} Pilot Travel Centers
                        LLC.
                    </span>
                    <span className="copyright">All rights reserved.</span>
                </div>
                <div className="links">
                    <Link
                        to="/privacy"
                        onClick={() =>
                            this.footerItemClicked('Terms and Conditions')
                        }
                        className="footer__copy"
                    >
                        Terms and Conditions
                    </Link>
                    <Link
                        to="/privacy#privacy"
                        onClick={() => this.footerItemClicked('Privacy')}
                        className="footer__copy"
                    >
                        Privacy
                    </Link>
                    <a
                        href="https://privacy.pilotflyingj.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="footer__copy"
                    >
                        Do Not Sell or Share My Personal Information
                    </a>
                </div>
            </FooterStyled>
        );
    }
}

export default Footer;
