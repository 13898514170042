/* LIBRARIES */
import React from 'react';
import { ThemeProvider } from 'styled-components';

const theme = {
    borderRadius: {
        default: '3px',
        card: '4px',
    },
    boxShadow: '0 0 8px rgba(0, 0, 0, 0.25);',
    colors: {
        error: '#cf0a2c',
        primaryYellow: '#f9a11b',
        secondaryYellow: '#ff9c00',
        primaryGray: '#212721',
        secondaryGrey: '#1d2521',
        truckerBurgerRed: '#a0292a',
        lightGray: '#b5b5b5',
        white: '#fff',
        grey: '#757575',
        textGrey: '#4a4a4a',
        textGraySecondary: '#404041'
    },
    fonts: ['Acumin-Pro', 'Erbaum', 'Museo-Sans'],
    fontSizes: {
        small: '1em',
        medium: '2em',
        large: '3em',
    },
};

const Theme = ({ children }) => (
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;
