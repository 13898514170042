import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';
//import { Event } from '../Tracking/Tracking';

const ButtonStyled = styled.span`
    display: flex;
    justify-content: center;
    background-color: ${props =>
        props.color === 'yellow'
            ? props.theme.colors.primaryYellow
            : props.theme.colors.primaryGray};
    border: none;
    border-radius: ${props => props.theme.borderRadius.default};
    font: 1.125rem ${props => props.theme.fonts[1]}, sans-serif;
    line-height: 1.5625rem;
    text-align: center;
    text-transform: uppercase;
    box-sizing: border-box;
    cursor: pointer;

    a {
        padding: ${props => props.buttonVerticalPadding}
            ${props => props.buttonSidePadding};
        color: ${props =>
            props.color === 'yellow'
                ? props.theme.colors.primaryGray
                : props.theme.colors.primaryYellow};
    }

    &:hover {
        a {
            color: ${props =>
                props.color === 'yellow'
                    ? props.theme.colors.primaryYellow
                    : props.theme.colors.primaryGray};
        }
        background-color: ${props =>
            props.color === 'yellow'
                ? props.theme.colors.primaryGray
                : props.theme.colors.primaryYellow};
    }
`;

const Button = ({
    buttonSidePadding,
    buttonVerticalPadding,
    clicked,
    color,
    id,
    isExternal,
    isPageAnchor,
    url,
    text,
}) => (
    <ButtonStyled
        buttonSidePadding={buttonSidePadding}
        buttonVerticalPadding={buttonVerticalPadding}
        color={color}
        className="button"
    >
        {!isExternal && !isPageAnchor ? (
            <Link
                to={url}
                id={id ? id : null}
                onClick={clicked ? e => clicked(e) : null}
            >
                {text}
            </Link>
        ) : (
            <a
                href={url}
                id={id ? id : null}
                target={isPageAnchor ? '_self' : '_blank'}
                rel="noreferrer noopener"
                onClick={clicked ? e => clicked(e) : null}
            >
                {text}
            </a>
        )}
    </ButtonStyled>
);

Button.propTypes = {
    buttonSidePadding: PropTypes.string,
    buttonVerticalPadding: PropTypes.string,
    color: PropTypes.string,
    id: PropTypes.string,
    isExternal: PropTypes.bool,
    isPageAnchor: PropTypes.bool,
    url: PropTypes.string,
    text: PropTypes.string.isRequired,
    clicked: PropTypes.func,
};

Button.defaultProps = {
    buttonSidePadding: '50px',
    buttonVerticalPadding: '11px',
    color: 'yellow',
    isExternal: false,
    isPageAnchor: false,
};

export default Button;
