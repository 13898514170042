import React from 'react';

const navClose = (props) => (
    <svg className="menu__close" width="38px" height="38px" viewBox="0 0 38 38" version="1.1" onClick={props.clicked}>
        <g id="Symbols-" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="logo_menu_mobile_exit" transform="translate(-297.000000, -6.000000)">
                <g id="exit_button">
                    <g transform="translate(297.000000, 6.000000)">
                        <g>
                            <rect id="Rectangle" fill="#212721" x="0" y="0" width="38" height="38" rx="4"></rect>
                            <polygon id="X" fill="#F9A11B" fillRule="nonzero" points="18.0185647 14.3737 19.2923077 16.8036098 20.5856468 14.334508 21.8397937 12.2769231 26.0137516 12.2769231 26.0137516 12.4924796 21.7026214 19.0963472 26.3076923 25.8373872 26.3076923 25.9941556 22.0945423 25.9941556 20.8207993 24.0933391 19.2923077 21.545853 17.74422 24.1325312 16.4900731 25.9941556 12.2769231 25.9941556 12.2769231 25.8373872 16.881994 19.0963472 12.5708638 12.4924796 12.5708638 12.2769231 16.7448217 12.2769231"></polygon>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export default navClose;