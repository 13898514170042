import axios from 'axios';
const apiURL = process.env.PFJ_BASE_API_URL;
const expLayer = process.env.ONE9_EXPERIENCE_LAYER_URL;

export const getPageContent = async (store, request = axios) => {
    let status = 'LOADING';
    store.setState({ status });
    try {
        const response = await axios.get(`${apiURL}${expLayer}content`);
        const content = response.data;
        const truckerBurger = response.data.truckerBurger.items[0];

        const isContentEmpty = content.length === 0;
        status = isContentEmpty ? 'EMPTY' : 'SUCCESS';
        store.setState({ content, truckerBurger, status });
    } catch (error) {
        console.log(error);
        const isError404 = error.response && error.response.status === 404;
        status = isError404 ? 'NOT_FOUND' : 'ERROR';
        store.setState({ status });
    }
};
