import { getFilteredBlogsService } from './FilteredArticlesServices';
import { navigate } from '@reach/router';

export const loadNextBlogList = async (store, contentFilter, nextOrPrev) => {
    var blogPosts = await getFilteredBlogsService(store, contentFilter);
    var selectedBlog;
    if (nextOrPrev === 'Next') {
        selectedBlog = blogPosts[0];
    } else {
        selectedBlog = blogPosts[blogPosts.length - 1];
    }
    store.setState({ selectedBlog });
    let permalink = '/blog/' + selectedBlog.slug;
    navigate(permalink, { replace: false });
};
