import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Hamburger from './UI/Hamburger';
import Logo from './UI/Logo';
import Menu from './Menu/Menu';
import MobileNav from './Menu/MobileNav';

const HeaderStyled = styled.header`
    position: fixed;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 92px;
    padding: 0 20px;
    background-color: rgba(0, 0, 0, 0);
    box-sizing: border-box;
    z-index: 9000;

    &.solid {
        transition: background-color 0.25s ease-out;
        background-color: ${props => props.theme.colors.primaryGray};
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    }

    .hamburger {
        cursor: pointer;
        z-index: 9999;
    }

    @media screen and (min-width: 1024px) {
        justify-content: center;
        padding: 0;
        width: 100%;
        height: 142px;
        padding: 0 20px;
    }
`;

const SpacerStyled = styled.div`
    height: 92px;

    @media screen and (min-width: 1024px) {
        height: 142px;
    }
`;

class Header extends Component {
    state = {
        needsSolidHeader: false,
        needsSpacer: false,
    };

    componentDidMount() {
        this.makeHeaderSticky();
        window.onscroll = () => {
            if (
                window.location.pathname === '/' ||
                window.location.href.indexOf('trucker-burger') > -1
            ) {
                this.makeHeaderSticky();
            }
        };

        this.setState({
            needsSolidHeader: this.props.needsSolidHeader,
            sticky: this.refs.header.offsetTop + 92,
        });
    }

    makeHeaderSticky = () => {
        if (window.pageYOffset > 142) {
            this.setState({ needsSolidHeader: true });
        } else if (window.pageYOffset <= 142 && this.props.needsSolidHeader) {
            this.setState({ needsSolidHeader: true, needsSpacer: true });
        } else {
            this.setState({ needsSolidHeader: false });
        }
    };
    render() {
        const {
            closeClicked,
            hamburgerClicked,
            hideNav,
            isDesktop,
            menuItemClicked,
            showMobileNav,
        } = this.props;

        let solidHeaderClass = this.state.needsSolidHeader ? 'solid' : null;
        const headerClasses = ['header', solidHeaderClass].join(' ');
        let logoProps = {
            className: 'header__logo',
            oneColor: '#FEFEFE',
            nineColor: '#FEFEFE',
            squareColor: '#F9A11B',
            fuelNetworkColor: '#FEFEFE',
            width: '119',
            height: '50',
        };
        if (isDesktop) {
            logoProps = {
                ...logoProps,
                width: '162',
                height: '72',
            };
        }

        return (
            <React.Fragment>
                <HeaderStyled className={headerClasses} ref="header">
                    {isDesktop ? (
                        <Menu
                            hideNav={hideNav}
                            logoProps={logoProps}
                            logoClicked={this.props.logoClicked}
                            menuItemClicked={menuItemClicked}
                        />
                    ) : (
                        <React.Fragment>
                            <Logo
                                {...logoProps}
                                clicked={this.props.logoClicked}
                            />
                            <Hamburger clicked={hamburgerClicked} />
                            {showMobileNav ? (
                                <MobileNav
                                    closeClicked={closeClicked}
                                    hideNav={hideNav}
                                    menuItemClicked={menuItemClicked}
                                />
                            ) : null}
                        </React.Fragment>
                    )}
                </HeaderStyled>
                {this.state.needsSpacer ? <SpacerStyled /> : null}
            </React.Fragment>
        );
    }
}

Header.propTypes = {
    needsSolidHeader: PropTypes.bool,
    siteTitle: PropTypes.string,
};

Header.defaultProps = {
    needsSolidHeader: false,
    siteTitle: ``,
};

export default Header;
