import axios from 'axios';
import { applyDateFormat, applyContentLabels } from './BlogUtilitiesService.js';
const blogUrl = process.env.PFJ_BLOG_URL;

export const getPostBySlug = async (store, slug) => {
    const status = 'LOADING';
    store.setState({ status });
    try {
        var filterQueryText =
            '{"filter": {"path": "data.slug.iv", "op": "eq", "value":"' +
            slug +
            '"}}';
        var encodedFilterQuery = encodeURIComponent(filterQueryText);

        const response = await axios.get(blogUrl + encodedFilterQuery);

        var selectedBlog = response?.data?.items;

        selectedBlog = await applyDateFormat(selectedBlog);
        selectedBlog = await applyContentLabels(selectedBlog);
        selectedBlog = selectedBlog[0];

        const status = 'SUCCESS';
        store.setState({ status });
        store.setState({ selectedBlog });
        return selectedBlog;
    } catch (error) {
        const isError404 = error.response && error.response.status === 404;
        const status = isError404 ? 'NOT_FOUND' : 'ERROR';
        store.setState({ status });
    }
};
