import { getPosts } from './BlogCoreService.js';

// Get the four most recent blogs
export const getRecentBlogs = async store => {
    if (store.state.recentBlogs == null) {
        var contentFilter = {
            activePage: 1,
            pageSize: 4,
            activeDaysFilter: 0,
            activeTypeFilters: null,
        };
        var recentBlogs = await getPosts(store, undefined, contentFilter);
        store.setState({ recentBlogs });
    }
};
