import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
    * {
        padding: 0;
        margin: 0;
    }

    body { 
        font-family: ${props => props.theme.fonts[0]}, sans-serif;
        font-weight: 200;
        color: ${props => props.theme.colors.primaryGray};
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        font-family: ${props => props.theme.fonts[1]}, sans-serif;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
    }

    h1 {
        font-size: 2.375rem;
        line-height: 2.1875rem;
    }

    h2 {
        font-size: 1.875rem;
        line-height: 2.375rem;
    }

    h3 {
        font-size: 1.5rem;
        line-height: 1.625rem;
    }

    h4 {
        font-size: 1.25rem;
        line-height: 1.25rem;
    }

    @media screen and (min-width: 768px) {
        h1 {
            font-size: 3.75rem;
            line-height: 3.375rem;
        }

        h2 {
            font-size: 2.5rem;
            line-height: 3rem;
        }

        h3 {
            font-size: 1.875rem;
            line-height: 2.1875rem;
        }

        h4 {
            font-size: 1.25rem;
            line-height: 1.25rem;
        }
    }

    a {
        color: #fff;
        text-decoration: none;
    }
`;

export default GlobalStyles;
