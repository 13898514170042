import axios from 'axios';
import { applyDateFormat, applyContentLabels } from './BlogUtilitiesService.js';
const blogUrl = process.env.PFJ_BLOG_URL;

export const loadPreviousBlog = async store => {
    const status = 'LOADING';
    store.setState({ status });
    try {
        var filterQueryText =
            '{"filter": {"and": [{"path": "data.publicationDate.iv", "op": "ge", "value":"' +
            store.state.selectedBlog.originalPublicationDate +
            '"}, {"path": "data.slug.iv", "op": "ne", "value":"' +
            store.state.selectedBlog.slug +
            '"}]}, "sort":[{"path": "data.publicationDate.iv", "order":"descending"}], "take":1 }';

        var encodedFilterQuery = encodeURIComponent(filterQueryText);

        const response = await axios.get(blogUrl + encodedFilterQuery);

        var selectedBlog = response?.data?.items;

        if (
            selectedBlog == null ||
            selectedBlog.data == null ||
            selectedBlog.data.items == null ||
            selectedBlog.data.items.length < 1
        ) {
            selectedBlog = await fallbackToMostRecent();
        }

        selectedBlog = await applyDateFormat(selectedBlog);
        selectedBlog = await applyContentLabels(selectedBlog);
        selectedBlog = selectedBlog[0];

        const status = 'SUCCESS';
        store.setState({ status });
        store.setState({ selectedBlog });
        return selectedBlog;
    } catch (error) {
        const isError404 = error.response && error.response.status === 404;
        const status = isError404 ? 'NOT_FOUND' : 'ERROR';
        store.setState({ status });
    }
};

export const fallbackToMostRecent = async store => {
    const status = 'LOADING';
    store.setState({ status });
    try {
        var filterQueryText =
            '{"path": "data.slug.iv", "op": "ne", "value":"' +
            store.state.selectedBlog.slug +
            '"}]}, "sort":[{"path": "data.publicationDate.iv", "order":"descending"}], "take":1 }';

        var encodedFilterQuery = encodeURIComponent(filterQueryText);

        const response = await axios.get(blogUrl + encodedFilterQuery);

        var selectedBlog = response?.data?.items;

        const status = 'SUCCESS';
        store.setState({ status });
        return selectedBlog;
    } catch (error) {
        const isError404 = error.response && error.response.status === 404;
        const status = isError404 ? 'NOT_FOUND' : 'ERROR';
        store.setState({ status });
    }
};
